import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { t } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import Icon from 'components/ui/Icon';
import { setStateItem } from 'utils/Item';
import ColumnsModal from 'components/modal/ColumnsModal';
import Pagination from 'components/ui/Pagination';
import DatatableRow, { Cell } from './DatatableRow';
import NoDataAlert from './NoDataAlert';
import DatatableFilters from './DatatableFilters';

function normalizeColumns(columnsList, key, location) {
  const params = new URLSearchParams(location.search);
  if (columnsList.length < 1) return columnsList;
  const storedData = localStorage.getItem(key);
  const stored = storedData ? JSON.parse(storedData) : null;
 
  const inactive = columnsList.filter(c => !('active' in c) || !c.active);
  if (inactive.length === columnsList.length) {
    for (let i = 0; i < columnsList.length; i++) {
      columnsList[i].active = stored !== null ? stored.includes(columnsList[i].name) : true;
      columnsList[i].isLast = false;
      columnsList[i].group = 'group' in columnsList[i] ? columnsList[i].group : 'DEFAULT';
    }
  }
  else {
    for (let i = 0; i < columnsList.length; i++) {
      if (stored !== null) {
        columnsList[i].active = stored !== null ? stored.includes(columnsList[i].name) : true;
      }
      else if (!('active' in columnsList[i]) || !columnsList[i].active) {
        columnsList[i].active = false;
      }
      columnsList[i].isLast = false;
      columnsList[i].group = 'group' in columnsList[i] ? columnsList[i].group : 'DEFAULT';
    }
    
  }
  columnsList.findLast(c => c.active).isLast = true;
  if (params.has('set_columns')) {
    const set_columns = params.get('set_columns').split(',');
    for (let i = 0; i < columnsList.length; i++) {
      if (set_columns.includes(columnsList[i].name)) {
        columnsList[i].active = true;
      }
    }
  }

  return columnsList;
}

function DatatableBody(props) {
  const { columns, data, loadData, editHandler, canEdit, canDelete, onDelete, columnsList,
    deleteConfirmation, confirmValue, confirmValueLabel, actions, actionsDropdown,
    hasCheckboxes, selectedPks, setSelectedPk, rowClasses } = props;
  const history = useHistory();

  if (data === null) {
    return null;
  }
  if (data.items.length < 1) {
    return (
      <tbody>
        <tr>
          <td colSpan={columns.length}>
            <NoDataAlert/>
          </td>
        </tr>
      </tbody>
    );
  }

  const onDeleteRow = (item) => {
    axios.delete(`${props.api}/${item.pk}`)
      .then(() => {
        if (onDelete) onDelete();
        loadData();
      })
      .catch((error) => addMessage('delete-error', t`Server error`, error.response.data.message));
  };

  const onEdit = editHandler !== false ?
    (typeof editHandler === 'function') ?
      editHandler :
      (item) => {
        history.push(editHandler.replace(':id', item.pk));
      }
    : null;
  return (
    <tbody>
      { data.items.map(item => (
        <DatatableRow
          key={item.pk}
          actions={actions}
          item={item}
          onEdit={onEdit}
          canEdit={canEdit}
          onDelete={onDeleteRow}
          canDelete={canDelete}
          deleteConfirmation={deleteConfirmation}
          confirmValue={confirmValue}
          confirmValueLabel={confirmValueLabel}
          actionsDropdown={actionsDropdown}
          rowClasses={rowClasses}>
          { hasCheckboxes && (
            <td className="select-row">
              <Form.Check
                name={'select-item-' + item.pk}
                id={'select-item-' + item.pk}
                onChange={(e) => {
                  setSelectedPk(pks => {
                    if (e.target.checked) {
                      return [...pks, item.pk];
                    }
                    return pks.filter(pk => pk !== item.pk);
                  });
                }}
                aria-label={t`Select row: ` + item.resource_name}
                checked={selectedPks.includes(item.pk)}
              />
            </td>
          ) }
          { React.Children.map(columns, col => (col ? (
            <Cell 
              columnsList={columnsList} 
              key={col.props.name}
              label={col.props.label}
              item={item}
              contentBuilder={col.props.children ? col.props.children : null}
              value={col.props.name in item ? item[col.props.name] : null}
              {...col.props}>
            </Cell>
          ) : null)) }
        </DatatableRow>
      )) }
    </tbody>
  );
}
function DatatableHeadContent(props) {
  const { label, sortName, setSort, name, sortDir, sortList } = props;
  if (sortList.indexOf(name) !== -1) {
    return (
      <button className="btn btn-simplelink" type="button" onClick={() => setSort(name)}>
        {label}{' '}
        { sortName === name && (
          <Icon name={sortDir === 'ASC' ? 'arrow-down-circle' : 'arrow-up-circle'}/>
        ) }
      </button>
    );
  }
  return (
    <span>{ label }</span>
  );
}
function DatatableHead(props) {
  const { columns, columnsList, columnsKey, setColumns, hasCheckboxes, selectedPks, setSelectedPk, data, ...rest } = props;

  const cols = [];
  if (hasCheckboxes && data && ('items' in data) && data.items) {
    const shownPks = data.items.map(i => i.pk);
    const isActive = shownPks.every(pk => selectedPks.includes(pk));
    cols.push(
      <th key="select-all">
        <Form.Check
          name="select-all"
          id="select-all"
          onChange={e => {
            setSelectedPk(pks => {
              if (e.target.checked) {
                return Array.from(new Set([...pks, ...shownPks]));
              }
              return pks.filter(pk => !shownPks.includes(pk));
            });
          }}
          checked={isActive} />
      </th>
    );
  }
  React.Children.map(columns, (col, index) => {
    if (col === null) return;
    const name = col.props.sort || col.props.name;
    const colDef = columnsList.find(c => c.name === name);
    if (!colDef || colDef.active) {
      // eslint-disable-next-line prefer-destructuring
      let label = col.props.label;
      if (columnsList && !label && colDef) {
        label = colDef.label || '';
      }
      cols.push(
        <th key={name}>
          <DatatableHeadContent label={label} name={name} {...rest} />
        </th>
      );
    }
  });
  return (
    <thead>
      <tr>
        { cols }
        <td className="col__actions">
          { columnsList.length > 0 && (
            <ColumnsModal setColumns={setColumns} columnsList={columnsList} columnsKey={columnsKey} />
          ) }
        </td>
      </tr>
    </thead>
  );
}

function Datatable(props) {
  const { pageSize, apiContext, editHandler, canEdit, filtersBase, filtersExtra, Entity,
    canDelete, onDelete, api, search, deleteConfirmation, confirmValue, columnsKey, columnsList, rowClasses, defaultSort, defaultSortDir,
    confirmValueLabel, actions, refresh, actionsDropdown, customFilters, filtersData, selectedComponents } = props;
  const datatableContainer = useRef();
  const hasCheckboxes = selectedComponents.length > 0;
  const [filtersItems, setFiltersItems] = useState(null);
  const [sortList, setSortList] = useState([]);
  const [data, setData] = useState(null);
  const [requestParams, setRequestParams] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sortName, setSortName] = useState(defaultSort);
  const [selectedPks, setSelectedPk] = useState([]);
  const [sortDir, setSortDir] = useState(defaultSortDir);
  const location = useLocation();
  const [columnsNormalized, setColumns] = useState(normalizeColumns(columnsList, columnsKey, location));
  const defaultPageSize = pageSize || process.env.REACT_APP_DATATABLE_DEFAULT_PAGE_SIZE;
  const [pSize, setPSize] = useState(defaultPageSize);
  const pSizes = [10, 20, 50];
  if (!pSizes.includes(parseInt(defaultPageSize, 10))) {
    pSizes.push(parseInt(defaultPageSize, 10));
    pSizes.sort((a, b) => a - b);
  }

  const setSort = useCallback(function(name) {
    if (sortName === name) {
      setSortDir(sortDir === 'ASC' ? 'DESC' : 'ASC');
    }
    else {
      setSortName(name);
      setSortDir('ASC');
    }
  }, [sortDir, sortName]);

  const loadFilters = useCallback(function() {
    axios.get(api + '/meta/list').then((res) => {
      setFiltersItems(customFilters.length ? [...res.data.filters, ...customFilters] : res.data.filters);
      setSortList(res.data.orders);
    }).catch((error) => {
      addMessage('filters-' + api, t`Unknown error`, t`Impossible to load filters`);
    });
  }, [api, customFilters]);

  const loadData = useCallback(function() {
    setLoading(true);
    const apiParams = new URLSearchParams(location.search);
    if (apiContext) {
      for (const key in apiContext) {
        apiParams.append(key, apiContext[key]);
      }
    }
    apiParams.append('page', page);
    apiParams.append('page_size', pSize);
    if (sortName) {
      apiParams.append('order', (sortDir === 'ASC' ? '' : '-') + sortName);
    }
    customFilters.filter(c => (('buildParams' in c) && c.buildParams)).forEach(filter => {
      filter.buildParams(apiParams);
    });
    axios.get(`${api}?subset=pagination`, { params: apiParams }).then((res) => {
      setData(res.data);
      setRequestParams(apiParams);
      for (const it of res.data.items) {
        if (Entity) {
          const bl = new Entity();
          bl.loadFromObject(it);
          setStateItem(api + ':' + it.pk, bl, { refreshParent: false });
        }
        else {
          setStateItem(api + ':' + it.pk, it, { refreshParent: false });
        }
      }
      setLoading(false);
      if (datatableContainer.current && ('scroll' in datatableContainer.current.dataset)) {
        delete datatableContainer.current.dataset.scroll;
        datatableContainer.current.scrollIntoView();
      }
    }).catch((e) => {
      console.error(e);
      addMessage('datatable-load-data', t`Unknown error`, t`Impossible to load table items`);
    });
  }, [apiContext, api, page, location.search, pSize, sortDir, sortName, Entity, customFilters]);

  useEffect(() => {
    loadFilters();
  }, [loadFilters]);

  useEffect(() => {
    loadData();
  }, [loadData, refresh, sortDir, sortName]);

  return (
    <div className="datatable">
      <DatatableFilters
        apiContext={apiContext}
        setPage={setPage}
        data={data}
        pageSize={pSize}
        setPSize={setPSize}
        pSizes={pSizes}
        filtersItems={filtersItems}
        search={search}
        filtersBase={filtersBase}
        filtersExtra={filtersExtra}
        filtersData={filtersData}
        selectedPks={selectedPks}
        setSelectedPk={setSelectedPk}
        selectedComponents={selectedComponents}
        requestParams={requestParams} />
      <div className="relative">
        { loading && (
          <LoaderContainer />
        ) }
        { columnsNormalized.length > 0 && (
          <div className="d-md-none">
            <ColumnsModal setColumns={setColumns} columnsList={columnsNormalized} columnsKey={columnsKey} mobile />
          </div>
        ) }
        <div className="datatable-table-container" ref={datatableContainer}>
          <table className={'table table-datatable table-responsive' + (hasCheckboxes ? ' table-checkboxes' : '')}>
            <DatatableHead
              columns={props.children}
              sortList={sortList}
              setSort={setSort}
              sortDir={sortDir}
              sortName={sortName}
              columnsList={columnsNormalized}
              columnsKey={columnsKey}
              setColumns={setColumns}
              hasCheckboxes={hasCheckboxes}
              data={data}
              selectedPks={selectedPks}
              setSelectedPk={setSelectedPk} />
            <DatatableBody
              loadData={loadData}
              columns={props.children}
              data={data}
              editHandler={editHandler}
              canEdit={canEdit}
              canDelete={canDelete}
              onDelete={onDelete}
              deleteConfirmation={deleteConfirmation}
              confirmValue={confirmValue}
              confirmValueLabel={confirmValueLabel}
              api={api}
              actions={actions}
              actionsDropdown={actionsDropdown}
              columnsList={columnsNormalized}
              hasCheckboxes={hasCheckboxes}
              selectedPks={selectedPks}
              setSelectedPk={setSelectedPk}
              rowClasses={rowClasses} />
          </table>
          { data && (
            <Pagination
              type="mini"
              className="justify-content-end"
              count={Math.ceil(data.meta.subset.count / pSize)}
              page={data.meta.subset.page}
              onNav={p => {
                if (datatableContainer.current) {
                  datatableContainer.current.dataset.scroll = 'scroll';
                }
                setPage(p);
              }} />
          ) }
          
        </div>
      </div>

    </div>
  );
}
Datatable.defaultProps = {
  search: true,
  canEdit: item => (true),
  canDelete: true,
  hideFilters: [],
  customFilters: [],
  actions: () => [],
  actionsDropdown: false,
  apiContext: {},
  columnsList: [],
  selectedComponents: [],
  defaultSortDir: 'ASC'
};

function Column(props) {
  return <th key={props.name}>{props.label}</th>;
}
Column.defaultProps = {
  label: ''
};
Datatable.Column = Column;

export default Datatable;
