import { t } from '@lingui/macro';
import { modelsApi } from 'components/utils/constants';
import Entity from 'entity/Entity';

export default class Organization extends Entity {
  constructor() {
    super(['orgSlug'], modelsApi.Organization.api + '/slug/:orgSlug', modelsApi.Organization.api, [
      'pk',
      'name',
    ]);
  }

  _getStateKey() {
    return this._genericApi + ':' + this.slug;
  }

  get is_account_manager() {
    return this.member.roles.some(r => r[0] === 'role_account_manager');
  }

  get is_organization_manager() {
    return this.member.roles.some(r => r[0] === 'role_organization_manager');
  }

  get is_member() {
    return this.member.roles.some(r => r[0] === 'role_member');
  }

  get is_guest() {
    return this.member.roles.some(r => r[0] === 'role_guest');
  }

  get can_add_member() {
    return this.is_organization_manager;
  }

  can_edit_member(member, user) {
    return this.is_organization_manager && member.user.pk !== user.pk;
  }

  can_delete_member(member, user) {
    return this.is_organization_manager && member.user.pk !== user.pk;
  }

  get is_members_limit_reached() {
    return this.nb_members >= this.max_users && this.max_users >= 0;
  }

  get is_guests_limit_reached() {
    return this.nb_guests >= this.max_guests && this.max_guests >= 0;
  }

  get can_add_customer() {
    return this.is_active && this.is_organization_manager;
  }

  get can_edit_customer() {
    return this.is_active && this.is_organization_manager;
  }

  get can_delete_customer() {
    return this.is_active && this.is_organization_manager;
  }

  get can_add_account() {
    return this.is_active && this.is_organization_manager;
  }

  get can_edit_account() {
    return this.is_active && this.is_organization_manager;
  }

  get can_delete_account() {
    return this.is_active && this.is_organization_manager;
  }

  get can_add_product() {
    return this.is_active && this.is_organization_manager;
  }

  has_feature(feature) {
    return this.features.includes(feature);
  }

  getPath() {
    return '/' + this.resource_slug;
  }

  getLinks() {
    const prefix = this.getPath() + '/';
    const links = [
      {
        name: 'products',
        title: t`Products`,
        path: prefix + 'manage/products',
      },
      {
        name: 'worklogs',
        title: t`Worklogs`,
        path: prefix + 'manage/worklogs',
      }
    ];
    return links;
    
  }
}
