/* eslint-disable
  no-lonely-if,
  jsx-a11y/no-noninteractive-element-interactions,
  jsx-a11y/no-static-element-interactions,
  jsx-a11y/click-events-have-key-events */
import { t, Trans } from '@lingui/macro';
import React, { useEffect, useRef } from 'react';
import { createPopper } from '@popperjs/core';
import axios from 'axios';
import { apiSearchUrl } from 'utils/urlUtils';
import { Loader } from './Loader';

function stringify(str) {
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

const resourceName = function(obj) {
  if (obj.resource_reference) {
    return obj.resource_reference + ': ' + obj.resource_name;
  }
  return obj.resource_name;
};

const Item = function(props) {
  const { option, searchVal, preselection, changeSelected, validate, focusedItem } = props;
  const ref = useRef();

  useEffect(() => {
    if (preselection && focusedItem === option.pk) ref.current.focus();
  }, [preselection, focusedItem, option.pk]);

  const mouseEnter = () => {
    changeSelected(option.pk);
  };

  const resName = resourceName(option);
  let finalText = resName;
  if (searchVal) {
    const re = new RegExp(stringify(searchVal), 'g');
    const array1 = re.exec(resName);
    if (array1 !== null) {
      finalText = (
        <>
          { resName.substring(0, re.lastIndex - searchVal.length) }
          <mark>{ resName.substring(re.lastIndex - searchVal.length, re.lastIndex) }</mark>
          { resName.substring(re.lastIndex) }
        </>
      );
    }
  }
  return (
    <button
      className={'btn ' + (preselection ? 'btn-primary' : 'btn-outline-secondary')}
      onMouseEnter={mouseEnter}
      onClick={validate}
      ref={ref}
      type="button"
      tabIndex="-1">
      { finalText }
    </button>
  );
};

/**
 * props:
 *  pageSize: Maximum elements shown (opt | default to 8)
 *  nullText: Text shown for null value (opt | default to t'None')
 *  value: Actual field value (opt, object with pk and resource_name)
 *  required
 */
class SelectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.value || null,
      opened: false,
      options: [],
      preselection: props.value && props.value.pk,
      focusedItem: null,
      loading: true,
      showSearch: true,
      isValid: null
    };

    this.buttonRef = React.createRef();
    this.popinRef = React.createRef();
    this.inputRef = React.createRef();

    this.toggleOpened = this.toggleOpened.bind(this);
    this.close = this.close.bind(this);
    this.handleBtnKeyDown = this.handleBtnKeyDown.bind(this);
    this.handlePopinKeyDown = this.handlePopinKeyDown.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.changeSelected = this.changeSelected.bind(this);
    this.validate = this.validate.bind(this);
    this.validateSelect = this.validateSelect.bind(this);
    this.isMount = true;
  }

  handleBtnKeyDown(e) {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      if (!this.state.opened) {
        this.setState({ opened: true });
      }
      else {
        this.handlePopinKeyDown(e);
      }
      e.preventDefault();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
  }

  handlePopinKeyDown(e) {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      this.setState(prevState => {
        if (prevState.options.length < 1) return {};
        const index = prevState.options.findIndex(el => el.pk === this.state.preselection);
        if (index !== -1) {
          if (e.code === 'ArrowDown') {
            if (index >= prevState.options.length - 1) {
              return { preselection: prevState.options[0].pk };
            }
            return { preselection: prevState.options[index + 1].pk };
          }
          if (index <= 0) {
            return { preselection: prevState.options[prevState.options.length - 1].pk };
          }
          return { preselection: prevState.options[index - 1].pk };
        }
        return e.code === 'ArrowDown' ? {
          preselection: prevState.options[0].pk
        } : {
          preselection: prevState.options[prevState.options.length - 1].pk
        };
      }, () => {
        this.setState(prevState => ({ focusedItem: prevState.preselection }));
      });

      e.preventDefault();
    }
    else if (e.code === 'Enter' || e.code === 'Space') {
      this.validateSelect();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
  }

  validateSelect() {
    if (this.state.preselection !== false) {
      if (this.props.onValidate) {
        this.setState(
          this.props.onValidate,
          () => {
            if (this.props.handleSelect) {
              this.props.handleSelect(this.state.selectedValue, this.state);
            }
          });
      }
      else {
        this.validate();
      }
    }
  }

  validate() {
    if (this.state.preselection !== false) {
      this.setState(prevState => {
        const selectedOption = prevState.options.find(el => el.pk === prevState.preselection);
        const newState = {
          opened: false,
          isValid: true
        };
        if (selectedOption || !this.props.required) {
          newState.selectedValue = selectedOption;
        }
        return newState;
      }, () => {
        if (this.props.handleSelect) {
          this.props.handleSelect(this.state.selectedValue, this.state);
        }
      });
    }

  }

  handleInputKeyDown(e) {
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      this.handlePopinKeyDown(e);
      e.preventDefault();
      e.stopPropagation();
    }
    else if (e.code === 'Space') {
      e.stopPropagation();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
  }

  onOpen() {
    if (!this.props.noPopper) {
      this.popperInstance = createPopper(this.buttonRef.current, this.popinRef.current, {
        placement: 'bottom-start',
      });
    }
    const el = this.props.parent ? document.querySelector(this.props.parent) : document;
    el.addEventListener('click', this.close);
    if (this.state.showSearch) {
      this.inputRef.current.focus();
    }
    else {
      this.setState(prevState => ({ focusedItem: prevState.preselection }));
    }
  }

  onClose() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
    const el = this.props.parent ? document.querySelector(this.props.parent) : document;
    el.removeEventListener('click', this.close);
  }

  close() {
    this.setState({ opened: false, focusedItem: null });
  }

  toggleOpened() {
    this.setState(prevState => ({
      opened: !prevState.opened
    }));
  }

  handleSearchChange(e) {
    this.setState({ loading: true });
    axios.get(
      apiSearchUrl(this.props.searchApi, {
        search: this.inputRef.current.value,
        limit: this.props.pageSize
      })
    ).then((res) => {
      if (this.isMount) this.setState({ options: this.getFinalOptions(res.data.items), loading: false });
    }).catch(err => {
      if (this.isMount) {
        console.error('Impossible to load items: ' +
          apiSearchUrl(this.props.searchApi, {
            search: this.inputRef.current.value,
            limit: this.props.pageSize
          }));
      }
    });
  }

  changeSelected(value) {
    this.setState({ preselection: value, focusedItem: value });
  }

  getFinalOptions(options) {
    const extra = [];
    const nullText = this.props.nullText || t`None`;
    if (!this.props.required) {
      extra.push({ pk: null, resource_name: (this.props.optionAll ? t`All` : nullText) });
    }
    if (this.props.optionNone) {
      extra.push({ pk: 'NONE', resource_name: nullText });
    }
    if ('filter' in this.props) {
      return this.props.filter([...extra, ...options]);
    }
    return [...extra, ...options];
  }

  warmupOptions() {
    axios.get(apiSearchUrl(this.props.searchApi, { limit: this.props.pageSize })).then((res) => {
      if (this.isMount) {
        const newState = { loading: false };
        newState.options = this.getFinalOptions(res.data.items);
        if (res.data.meta.subset.page_count < 2) {
          newState.showSearch = false;
        }
        if (this.props.value === null || (this.props.value && typeof this.props.value !== 'object')) {
          // eslint-disable-next-line eqeqeq
          const obj = newState.options.find(opt => opt.pk == this.props.value);
          if (obj) newState.selectedValue = obj;
        }
        if (newState.options.length === 1 && !this.props.value && this.props.autoSelect) {
          // eslint-disable-next-line prefer-destructuring
          newState.preselection = newState.options[0].pk;
        }
        this.setState(newState, () => {
          if ('preselection' in newState) {
            this.validate();
          }
        });
      }
    }).catch(err => {
      if (this.isMount) {
        console.error('Impossible to load items: ' +
          apiSearchUrl(this.props.searchApi, { limit: this.props.pageSize }));
      }
    });
  }

  componentDidMount() {
    if (this.props.options) {
      this.setState({
        options: this.props.options,
        showSearch: false
      });
      return;
    }
    this.warmupOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.opened !== prevState.opened) {
      if (this.state.opened) {
        this.onOpen();
      }
      else {
        this.onClose();
      }
    }
    if (this.state.options !== prevState.options && this.props.onOptionsChange) {
      this.props.onOptionsChange(this.state.options);
    }
    if (prevProps.value !== this.props.value && this.props.value === null && !this.props.required) {
      const nullText = this.props.nullText || t`None`;
      this.setState({ selectedValue: { pk: null, resource_name: (this.props.optionAll ? t`All` : nullText) } });
    }
    if (this.props.refreshKey !== prevProps.refreshKey) {
      this.warmupOptions();
    }
  }

  componentWillUnmount() {
    const el = this.props.parent ? document.querySelector(this.props.parent) : document;
    el.removeEventListener('click', this.close);
    this.isMount = false;
  }

  render() {

    const searchVal = (this.state.showSearch && this.inputRef.current) ?
      this.inputRef.current.value :
      null;

    let buttonText = '';
    const chooseText = t`Please choose a value`;
    const defaultText = this.props.optionAll ? t`All` : t`None`;
    if ('buttonText' in this.props) {
      buttonText = this.props.buttonText;
    }
    else if (this.state.selectedValue) {
      buttonText = this.state.selectedValue === 'NONE' ? t`None` : resourceName(this.state.selectedValue);
    }
    else {
      buttonText = this.props.required ? chooseText : (this.props.nullText || defaultText);
    }
    const baseClass = ['select-search dropdown'];
    if (this.props.className) baseClass.push(this.props.className);
    if (this.props.noPopper) baseClass.push('no-popper');
    baseClass.push(this.state.showSearch ? 'with-search' : 'without-search');

    const attributes = {};
    if ('id' in this.props) {
      attributes.id = this.props.id;
    }

    const btClass = ['form-select'];
    if (this.state.isValid && this.props.validation) {
      btClass.push('is-valid');
    }
    else if (this.props.isInvalid) {
      btClass.push('is-invalid');
    }
    if ('variant' in this.props && this.props.variant) {
      btClass.push(this.props.variant);
    }

    const dMenuClass = ['dropdown-menu'];
    if (this.state.opened) {
      dMenuClass.push('show');
    }
    if ('dMenu' in this.props && this.props.dMenu) {
      dMenuClass.push(this.props.dMenu);
    }

    return (
      <div
        {...attributes}
        className={baseClass.join(' ')}
        role="listbox"
        tabIndex="-1"
        onClick={e => { e.stopPropagation(); }}>
        <button
          ref={this.buttonRef}
          type="button"
          aria-haspopup="true"
          className={btClass.join(' ')}
          aria-expanded={this.state.opened ? 'true' : 'false'}
          onClick={this.toggleOpened}
          onKeyDown={this.handleBtnKeyDown}>
          { buttonText !== chooseText && (
            <span className="visually-hidden"><Trans>Change following value</Trans>:</span>
          ) }
          { buttonText }
        </button>
        <div
          className={dMenuClass.join(' ')}
          ref={this.popinRef}
          onKeyDown={this.handlePopinKeyDown}>
          { this.state.showSearch && (
            <div className="input-wrapper">
              <input
                ref={this.inputRef}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleInputKeyDown}
                type="text"
                className="form-control"/>
              { this.state.loading && (
                <Loader size="tiny" />
              ) }
            </div>
          ) }
          <div className="btn-group-vertical">
            { this.state.options.map(option => (
              <Item
                key={option.pk}
                focusedItem={this.state.focusedItem}
                option={option}
                preselection={option.pk === this.state.preselection}
                changeSelected={this.changeSelected}
                validate={this.validateSelect}
                searchVal={searchVal}/>
            )) }
          </div>
        </div>
      </div>
    );
  }
}
SelectSearch.defaultProps = {
  pageSize: 8,
  validation: true,
  noPopper: false,
  autoSelect: true,
  optionAll: false,
  optionNone: false,
  parent: null
};

export default SelectSearch;
